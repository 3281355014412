@use 'styles/_variables.scss' as *;

.wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $fd-colors-background-surface;
  margin-top: 16px;
  padding-top: 32px;
}

.section {
  width: 1200px;
  display: flex;
  justify-content: center;
  background-color: $fd-colors-background-surface;
  flex-direction: column;
  padding: 0 16px;
}

.title {
  color: #131314;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin-bottom: 20px;
  text-align: center;
}

.content {
  display: flex;
  @media screen and (max-width: calc($fd-breakpoints-desktop * 1px)) {
    flex-direction: column;
  }
}
