@use 'styles/_variables.scss' as *;

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-bottom: $fd-border-widths-border-width-010 solid $fd-colors-border-subtle;
  margin-bottom: 32px;
  @media screen and (max-width: calc($fd-breakpoints-tablet-portrait * 1px)) {
    display: none;
  }
}

.tab {
  background: none;
  cursor: pointer;
  border: none;
  color: $fd-text-mode-name;
  height: 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $fd-font-sizes-font-size-060;
  border-bottom: 2px solid rgba(255, 255, 255, 0); // ensures text is always on the same baseline
  padding: $fd-space-space-3;
  &.active {
    color: $fd-colors-link-default-base;
    border-bottom: 2px solid $fd-colors-link-default-base;
    font-weight: $fd-font-weights-body-medium-strong;
  }
}
