@use 'styles/_variables.scss' as *;

.container {
  @media (min-width: calc($fd-breakpoints-tablet-portrait * 1px)) {
    max-height: 400px;
  }
  @media (min-width: calc($fd-breakpoints-desktop * 1px)) {
    max-height: 400px;
  }
  width: 100%;
  // the below color is not in the variables file
  color: #445058;
  max-height: 700px;
  margin-bottom: 16px;

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    flex-direction: column;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .location {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
}
