@use 'styles/_variables.scss' as *;

.container {
  @media screen and (min-width: calc($fd-breakpoints-tablet-portrait * 1px)) {
    display: none;
  }
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.select {
  padding: 12px 1.5rem 12px 8px;
  border: 1px solid $fd-colors-border-subtle;
  font-size: 16px;
  margin-bottom: 16px;
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' width='32' height='24' viewBox='0 0 32 24'><polygon points='0,0 32,0 16,24' style='fill: rgb%28138, 138, 138%29'></polygon></svg>");
  background-origin: content-box;
  background-position: right -1rem center;
  background-repeat: no-repeat;
  background-size: 9px 6px;
  outline: none;
}

.label {
  color: $fd-colors-component-button-transparent-border-disabled;
  text-transform: uppercase;
  font-size: $fd-font-sizes-label-medium;
  letter-spacing: $fd-letter-spacings-label-medium;
}
