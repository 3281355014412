@use 'styles/_variables.scss' as *;

.container {
  min-height: 65px;
}

.info {
  color: #445058;
  display: inline-flex;
  align-items: baseline;
  flex-wrap: wrap;
  gap: 4px;
  font-weight: 700;
  margin-bottom: 8px;
}

.detailsLink {
  display: flex;
  align-items: center;
  color: $fd-colors-content-link;
  text-decoration: none;
  font-weight: 400;
  &:hover {
    color: $fd-colors-content-link-active;
    text-decoration: underline;
  }
}

.disclaimer {
  font-size: $fd-font-sizes-font-size-040;
  color: $fd-colors-content-subtle;
}
