@use '../../index.scss';


.globalheader{
  margin: 0px;
  padding: 0px;
  border: 0px;
  display: block;
  position: sticky;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  height: 66px;
  ;
}

.sportsbooknav {
  margin: 0px;
  padding: 0px;
  border: 0px;
  webkit-box-align: center;
  align-items: center;
  padding-left: 14px;
  padding-right: 14px;
  display: none;
  background: rgb(20, 147, 255);
}


.sportsbooknavlist{
  border: 0px;
  padding: 0px;
  line-height: 1.6;
  margin: 0px;
  list-style: none;
  margin-bottom: 0px;
  margin-left: 0px;
  list-style-position: initial;
  list-style-type: none;
  overflow: auto;
  font-size: 0.875rem;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
}

.sportsbooknavitem{

  border: 0px;
  font-size: inherit;
  margin: 0px;
  margin-bottom: 0px;
  padding: 0.15em 20px 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 36px;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  background: rgb(20, 147, 255);
}

.sportsbookanchor {
  margin: 0px;
  padding: 0px;
  border: 0px;
  background-color: transparent;
  line-height: inherit;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  color: rgb(255, 255, 255);
  cursor: pointer;
  display: flex;
  font-family: var(--font-shentoxFont);
  text-transform: uppercase;
  letter-spacing: 2px;
}

.sportsbookanchorspan {
    margin: 0px;
    padding: 0px;
    border: 0px;
    display: inline-block;
    font-family: Proxima-Nova;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0px;
}

.newwindowicon{
  margin: 0px;
  padding: 0px;
  border: 0px;
  line-height: inherit;
  font-family: FanDuel;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  color: rgb(20, 147, 255);
  padding-left: 10px;
  font-size: 14px;
  font-weight: normal;
}

.sportsbooknavitem{
  border: 0px;
  font-size: inherit;
  margin: 0px;
  margin-bottom: 0px;
  background: rgb(30, 55, 91);
  padding: 0.15em 20px 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 36px;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
  border-right: 1px solid rgb(68, 80, 88);
}

.sportsbooktitle{
margin: 0px;
padding: 0px;
border: 0px;
display: inline-block;
font-family: Proxima-Nova;
font-weight: bold;
text-transform: uppercase;
letter-spacing: 0px;
}
.sportsbooknavitem{
  border: 0px;
  font-size: inherit;
  margin: 0px;
  margin-bottom: 0px;
  background: rgb(30, 55, 91);
  padding: 0.15em 20px 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  height: 36px;
  color: rgb(255, 255, 255);
  -webkit-font-smoothing: antialiased;
}
