.bannerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.bannerWrapper {
  max-width: 75rem; /* Set max width to 75rem */
  width: 100%; /* Make it responsive */
  margin: 0 auto; /* Center the container */
  box-sizing: border-box;
}

.ctaLink {
  text-decoration: none;
  display: inline-block;
  width: 100%;
}
